<template>
  <div class="background">
    <v-container>
      <v-row class="mt-5 mb-16">
        <v-col cols="12" sm="8" md="8">
          <h1 left class="text-left">Welcome to SmartSize</h1>
        </v-col>
        <v-col class="helpcenter" cols="12" md="4" sm="4">
          <v-btn href="https://smartsize.zohodesk.eu/portal/en/kb" target="_blank" class="mx-2" width="250px"
            height="45px" depressed outlined>
            <v-icon class="mr-2">mdi-help-circle</v-icon>
            <div class="font-weight-black">Visit our Help Center</div>
          </v-btn></v-col>
      </v-row>
      <v-card class="my-5 pa-5">
        <v-card-title class="text-h6">
          Create a new Size Chart
        </v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="auto">
              <v-btn @click="goNew" class="mx-2 my-2" width="200px" height="45px" dark>
                <v-icon class="mr-2">mdi-plus-circle-outline</v-icon>

                <div>New Size Chart</div>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="pa-5">
        <v-card-title class="text-h6">
          See your Size Charts
        </v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="auto">
              <v-btn @click="redirectToDashboard" class="ma-2" width="140px" height="45px" depressed dark color="#000000">
                <div class="font-weight-black">Go to Dashboard</div>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
    <SmartSizeFooter></SmartSizeFooter>
  </div>
</template>

<script>
import SmartSizeFooter from "@/components/GlobalComponents/SmartSizeFooter.vue";

export default {
  data() {
    return {};
  },
  components: {
    SmartSizeFooter,
  },
  methods: {
    goNew() {
      this.$router.push({ name: "Templates" });
    },
    redirectToDashboard() {
      this.$router.push({ name: "Dashboard" });
    },
  },
};
</script>

<style>
.background {
  /* background-color: rgb(244, 245, 244); */
  background-color: #f3f5f7;
  min-height: 100vh;
}
</style>