<template>
  <!-- Icons -->
  <div class="background">
    <v-container class="pt-0">
      <Landing v-if="this.newGreet != 'true'"></Landing> 
      <Welcome v-if="this.newGreet == 'true'"></Welcome>
    </v-container>
  </div>
  <!-- Icons -->
</template>

<script>
import Welcome from "./Welcome.vue";
import createApp from '@shopify/app-bridge';
import Landing from "./Landing.vue";

var newGreet;
var greet;
var redirect;

export default {
  name: "AppBridge",
  beforeCreate() {
    const urlParams = new URLSearchParams(window.location.search);
    var uri = window.location.href;
    let shop = urlParams.get("shop");
    this.greet = urlParams.get("greet");
    this.redirect = urlParams.get("redirect");

    if(this.redirect == "no"){
        this.newGreet = "true";
        console.log(this.newGreet, "this.newGreet if--")
    }else{
        this.newGreet = "false";
        console.log(this.newGreet, "this.newGreet else")
    }

    if(this.greet == "true" && this.redirect == "true"){

      var urlParamss = new URLSearchParams(window.location.search);
      var shopname = urlParamss.get("shop");
      var auth = urlParamss.get("auth_token");
      var u_st = "admin.shopify.com/store/"+shopname;
      var readyToEncode = u_st.replace(".myshopify.com", "");
      var encodedString = btoa(readyToEncode); 

      const appp = createApp({
        apiKey: process.env.VUE_APP_SHOP_API_KEY,
        host: encodedString,
        forceRedirect: true,
      });
      window.location.href = 'https://'+readyToEncode+'/apps/'+process.env.VUE_APP_SHOP_API_KEY+'?shop='+shopname+'&auth_token='+auth+'&greet=false&redirect=false';

    }
  },
  components: {
    Landing,
    Welcome,
  },
};
</script>

<style>
.background {
  background-color: #f3f5f7;
  min-height: 100vh;
}
</style>